import { Hidden } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Image from "./image";

const Footer = () => {
  const footerLinks = {
    title: "Links",
    items: [
      { id: 1, displayText: "Home", link: "/" },
      { id: 2, displayText: "Over mij", link: "/over-mij" },
      { id: 3, displayText: "Missie", link: "/missie" },
      {
        id: 4,
        displayText: "Bemiddeling en advies",
        link: "/bemiddeling-en-advies",
      },
      { id: 5, displayText: "Tarieven", link: "/tarieven" },
      { id: 6, displayText: "Blogs", link: "/blogs" },
      { id: 7, displayText: "Contact", link: "/contact" },
    ],
  };

  return (
    <React.Fragment>
      <Box className="footer">
        <Grid
          container
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            justifyContent: "center",
            minHeight: 250,
            color: "#FFF",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <Grid
                xs={12}
                item
                container
                direction="column"
                justifyContent="space-between"
                style={{ marginTop: 20, padding: 20 }}
              >
                <div>
                  <Typography variant="h4" style={{ color: "grey" }}>
                    <Image
                      alt="M - Concilium"
                      filename="M_Concilium.png"
                      maxWidth={300}
                    />
                  </Typography>
                  <Typography variant="body2" style={{ color: "grey" }}>
                    Scheidingscoach en familiaal bemiddelaar
                  </Typography>
                </div>

                <Typography variant="body2" style={{ color: "grey" }}>
                  ©Concilium {new Date().getFullYear()}
                </Typography>
              </Grid>
            </div>
            <Hidden smDown={true}>
              <div
                style={{
                  height: "80%",
                  width: "1px",
                  marginTop: "10%",
                  backgroundColor: "grey",
                }}
              ></div>
            </Hidden>
          </div>
          <Grid
            style={{ padding: 10 }}
            item
            xs={12}
            sm={3}
            container
            direction="column"
            justifyContent="space-evenly"
            key={footerLinks.title}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "grey", fontWeight: "bold" }}
            >
              {footerLinks.title}
            </Typography>
            {footerLinks.items.map(link => (
              <Link
                href={link.link}
                variant="body2"
                style={{
                  color: "grey",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                key={link.id}
              >
                {link.displayText}
              </Link>
            ))}
          </Grid>

          <Grid
            style={{ padding: 10 }}
            item
            xs={12}
            sm={3}
            container
            direction="column"
            justifyContent="space-evenly"
            key="Locatie"
          >
            <Typography
              variant="subtitle1"
              style={{ color: "grey", fontWeight: "bold" }}
            >
              Bemiddelingslocatie Ronse
            </Typography>
            <Typography style={{ color: "grey" }} variant="subtitle2">
             Advocatenkantoor - Kantoor 33
            </Typography>
            <Typography style={{ color: "grey" }} variant="subtitle2">
            Grote markt 33
            </Typography>
            <Typography style={{ color: "grey" }} variant="subtitle2">
              9600 Ronse
            </Typography>
            <Typography style={{ color: "grey" }} variant="subtitle2">
              België
            </Typography>
            <Typography style={{ color: "grey" }} variant="subtitle2">
              <a
                style={{ all: "unset", paddingRight: 4, cursor: "pointer" }}
                href="tel:+32476387680"
              >
                <i style={{paddingRight: 4}} className="fa fa-phone"></i>+32 (0)476.38.76.80
              </a>
            </Typography>
            <Typography style={{ color: "grey" }} variant="subtitle2">
              <a
                style={{ all: "unset", paddingRight: 4, cursor: "pointer" }}
                href="mailto:Maarten@concilium-bemiddeling.be"
              >
                <i style={{paddingRight: 4}} className="fa fa-envelope"></i>
                Maarten@concilium-bemiddeling.be
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
export default Footer;
