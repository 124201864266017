import PropTypes from "prop-types";
import React from "react";
import Image from "./image";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Link from "gatsby-link";

interface Props {
  companyName: string;
}

const useStyles = makeStyles(theme => ({
  appBar: {
    color: "#233348",
    backgroundColor: "#FFF",
    maxWidth: 1200,
    margin: "auto",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  drawerList: {
    width: 250,
  },
  drawerToggle: {
    padding: 20,
  },

  menuLink: {
    all: "unset",
    cursor: "pointer",
    display: "inline-block",
    position: "relative",
    textDecoration: "none",
    margin: 8,
    paddingBottom: 8,
    "&:after": {
      content: "''",
      position: "absolute",
      width: "100%",
      transform: "scaleX(0)",
      height: "2px",
      bottom: "0",
      left: "0",
      backgroundColor: "#0b9444",
      transformOrigin: "bottom right",
      transition: "transform 0.25s ease-out",
    },
    "&, &:hover&:after": {
      transform: "scaleX(1)",
      transformOrigin: "bottom left",
    },
  },

  active: {
    display: "inline-block",
    position: "relative",
    textDecoration: "none",
    margin: 8,
    paddingBottom: 8,
    "&:after": {
      content: "''",
      position: "absolute",
      width: "100%",
      transform: "scaleX(1)",
      transformOrigin: "bottom left",
      height: "2px",
      bottom: "0",
      left: "0",
      backgroundColor: "#0b9444",
      transition: "transform 0.25s ease-out",
    },
  },
}));

const Header = ({ companyName }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navLinks = [
    { displayText: "Home", link: "/" },
    { displayText: "Over mij", link: "/over-mij" },
    { displayText: "Missie", link: "/missie" },
    {
      displayText: "Bemiddeling en advies",
      link: "/bemiddeling-en-advies",
    },
    { displayText: "Tarieven", link: "/tarieven" },
    { displayText: "Blogs", link: "/blogs" },
    { displayText: "Contact", link: "/contact" },
  ];

  return (
    <React.Fragment>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Hidden mdUp={true}>
            <IconButton
              className={classes.drawerToggle}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.toolbarTitle}
          >
            <a href="./">

            <Image
              alt="M - Concilium"
              filename="M_small.png"
              maxWidth={60}
              />
              </a>
          </Typography>

          <Hidden smDown={true}>
            {navLinks.map(item => (
              <Link
                className={classes.menuLink}
                activeClassName={classes.active}
                style={{ textTransform: "none" }}
                color="inherit"
                key={item.displayText}
                to={item.link}
              >
                <Typography variant="body2">{item.displayText}</Typography>
              </Link>
            ))}
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <List className={classes.drawerList}>
          {navLinks.map((item, index) => (
            <ListItem
              button
              key={item.displayText}
              to={item.link}
              component={Link}
            >
              <ListItemText primary={item.displayText} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
};

Header.propTypes = {
  companyName: PropTypes.string,
};

Header.defaultProps = {
  companyName: `vHealth`,
};

export default Header;
