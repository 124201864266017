/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  description: string;
  lang: string;
  meta: any;
  title: string;
}

function SEO({ description, lang, meta, title }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription =
    "Concilium - Bemiddelingspraktijk te Ronse. Contacteer Concilium of maak een afspraak voor meer informatie! Scheidingscoach en familiaal bemiddelaar.";
  const url = "https://concilium-bemiddeling.be";
  const siteTitle =
    "Concilium | Scheidingscoach en familiaal bemiddelaar te Ronse";
  return (
    <Helmet
      htmlAttributes={{
        lang: "nl-be",
      }}
      title={siteTitle}
      titleTemplate="Concilium | Scheidingscoach en familiaal bemiddelaar te Ronse"
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: "nl-be",
        },
        {
          property: `og:title`,
          content: siteTitle,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `twitter:url`,
          content: url,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: metaDescription,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: siteTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content:
            "bemiddeling,advies,dialoog,coaching,familiaal,familie,bemiddelaar,kinderen,echtscheiding,partners,overeenkomst",
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
